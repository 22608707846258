import { render, staticRenderFns } from "./ChangeNoticePop.vue?vue&type=template&id=6b904156"
import script from "./ChangeNoticePop.vue?vue&type=script&lang=js"
export * from "./ChangeNoticePop.vue?vue&type=script&lang=js"
import style0 from "./ChangeNoticePop.vue?vue&type=style&index=0&id=6b904156&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/AzDevOps/myagent/_work/11/s/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6b904156')) {
      api.createRecord('6b904156', component.options)
    } else {
      api.reload('6b904156', component.options)
    }
    module.hot.accept("./ChangeNoticePop.vue?vue&type=template&id=6b904156", function () {
      api.rerender('6b904156', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/pdf/popup/ChangeNoticePop.vue"
export default component.exports